import React, {useState} from "react"
import { navigate, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

import { useStaticQuery, graphql } from "gatsby"
import SlideShow from "../components/slideshow";

import * as styles from "../styles/pages/index.module.css"


const imgbrowsepaintcreate = "../images/home/browsepaintcreate.png"

const imgquandrant1 = "../images/home/quandrant1.jpg"
const imgquandrant2 = "../images/home/quandrant2.jpg"
const imgquandrant3 = "../images/home/quandrant3.jpg"
const imgquandrant4 = "../images/home/quandrant4.jpg"

const imgneedahand1 = "../images/home/needahand1.jpg"
const imgneedahand2 = "../images/home/needahand2.jpg"

const imgboysenapp = "../images/home/boysenapp.png"

const imgboysenappappstore = "../images/home/appstore.png"
const imgboysenappgoogleplay = "../images/home/googleplay.png"

const HomePage = () => {

	const desktopslidewd = 1200;
	const mobileslidewd = desktopslidewd;
	const desktopwdhtratio = desktopslidewd/627;
	const mobilewdhtratio = desktopwdhtratio;

	const [imgwdhtratio, setImgwdhtratio] = useState(mobilewdhtratio);
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
					}
				}
			}
		`
	)

	var structuredData = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"sameAs": [
			"https://www.instagram.com/boysenpaintsphilippines/",
			"https://twitter.com/boysentsd/",
			"https://www.facebook.com/boysenpaintsphilippines/"
		],
		"url": site.siteMetadata.siteUrl,
		"description": site.siteMetadata.description,
		"mainEntityOfPage": site.siteMetadata.title,
		"address": {
			"@type": "PostalAddress",
			"addressRegion": "Metro Manila",
			"postalCode": "1115",
			"addressCountry": "PH",
			"addressLocality": "Quezon City",
			"streetAddress": "292 D. Tuazon Street"
		},
		"email": "inquiry@boysen.com.ph",
		"name": site.siteMetadata.title,
		"image": site.siteMetadata.siteUrl+"/images/logo.png",
		"logo": site.siteMetadata.siteUrl+"/images/logo-square.png",
		"@id": site.siteMetadata.siteUrl
	}


	function slideclick(clickobj)
	{
		if (clickobj.slideindex === 0) {
			window.open("https://www.myboysen.com/tips-to-achieve-well-put-together-interiors/", "_blank");
		} else if (clickobj.slideindex === 1) {
			navigate("/about");
		} else if (clickobj.slideindex === 2) {
			window.open("https://www.myboysen.com/check-out-the-newly-repainted-boysen-color-bridge/", "_blank");
		} else {
			window.open("https://www.myboysen.com/the-color-library-faqs/","_blank");
		}
	}

	function resizeHandler(newdisplaytype)
	{
		if (newdisplaytype < 2) {
			setImgwdhtratio(desktopwdhtratio);
		} else {
			setImgwdhtratio(mobilewdhtratio);
		}
	}

	function generatesliderobj(srcfolder, type, numslides)
	{
		const basewd = type==="desktop"?desktopslidewd:mobileslidewd;
		const outwidthlist = [1024, 512, 256];

		var tmpstr = "";
		var outlist = [];
		var curobj = {};
		var tmpidx = 0;
		var outwidthlistidx = 0;

		while (tmpidx < numslides) {
			tmpidx++;

			tmpstr = "";

			outwidthlistidx = 0;
			while (outwidthlistidx < outwidthlist.length) {
				if (outwidthlist[outwidthlistidx] < basewd) {
					tmpstr = tmpstr + ","+ srcfolder+outwidthlist[outwidthlistidx]+"w/"+type+"-slider-"+tmpidx+".jpg "+outwidthlist[outwidthlistidx]+"w";
				}
				outwidthlistidx++;
			}

			curobj = {"imgsrc": srcfolder+type+"-slider-"+tmpidx+".jpg"};
			if (tmpstr.length > 0) {
				curobj.imgsrcset = srcfolder+type+"-slider-"+tmpidx+".jpg "+basewd+"w"+tmpstr;
			}
			outlist.push(curobj);
		}
		return outlist;

	}


	return (
		<Layout activePath='' pageTitle="Home" structuredData={structuredData} resizeHandler={resizeHandler}>
			<div className="text-centered">
				<SlideShow
					clickfunc={slideclick}
					imgwdhtratio={imgwdhtratio}
					sliderlist={generatesliderobj("https://files.boysencdn.com/boysen/site/img/home/", imgwdhtratio===desktopwdhtratio ? "desktop" : "mobile", 4)}
				/>
			</div>
			<div className={styles.quadrant}>
				<div className="text-centered">
					<h2 className={styles.quadranttitle}>
						<StaticImage layout={"fullWidth"}  src={imgbrowsepaintcreate} alt={"Browse. Paint. Create."} />
					</h2>
					<div>
						Explore <span className="nobr futuraboldfont">BOYSEN<span className={"registeredicon"}>&reg;</span></span> paint colors and products
					</div>
					 for the home and beyond
				</div>
				<div className={"text-centered "+styles.quadrantlinksection}>
					<div className={styles.quadrantlinksectionrow}>
						<Link to="/colorcollection">
							<StaticImage layout={"fullWidth"}  src={imgquandrant1} alt={"Color Collection"} />
						</Link>
						<Link to="/products">
							<StaticImage layout={"fullWidth"}  src={imgquandrant2} alt={"Paint Products"} />
						</Link>
					</div>
					<div className={styles.quadrantlinksectionrow}>
						<a target="_blank" rel="noreferrer" href="https://youtube.com/playlist?list=PLRcJt7eIU7zu-ZKdOfYetGJtyWNyAmREp&si=TXJWf5uTf4PSJSN-">
							<StaticImage layout={"fullWidth"}  src={imgquandrant3} alt={"Konstrukt Lunar Series"} />
						</a>
						<Link to="/products" >
							<StaticImage layout={"fullWidth"}  src={imgquandrant4} alt={"Industrial Coatings"} />
						</Link>

					</div>
				</div>
			</div>
			<div className={styles.needahand+" text-centered"}>
				<h2  className={"font-style-bold "+styles.sectiontitle+" "+styles.sectiontitlebottom}>Need a Hand?</h2>
				Free-to-use tools to make painting a little easier
				<div className={"text-centered "+styles.needahandlinksection}>
					<Link to="/paintingtools/calculator">
						<StaticImage layout={"fullWidth"}  src={imgneedahand1} alt={"Paint Calculator"} />
					</Link>
					<Link to="/paintingtools/choosepaint">
						<StaticImage layout={"fullWidth"}  src={imgneedahand2} alt={"Choose Your Paint"} />
					</Link>
				</div>
			</div>
			<div className={styles.mobileapp+" text-centered"}>
				<h2 className="fullblock-desktop-only">
					<div className={"font-style-bold "+styles.sectiontitle+" "+styles.sectiontitlemultiline}>Download the</div>
					<div className={"font-style-bold "+styles.sectiontitle+" "+styles.sectiontitlebottom}><span className="nobr futuraboldfont">BOYSEN<span className={styles.registeredicon+" registeredicon"}>&reg;</span></span> Mobile App</div>
				</h2>
				<div className={"text-centered "+styles.mobileappcontentarea}>
					<div className={styles.mobileappimage}>
						<StaticImage layout={"fullWidth"}  src={imgboysenapp} alt={"BOYSEN Mobile App"} />

					</div>
					<h2 className="fullblock-mobile-only">
						<div className={"font-style-bold "+styles.sectiontitle+" "+styles.sectiontitlemultiline}>Download the</div>
						<div className={"font-style-bold "+styles.sectiontitle+" "+styles.sectiontitlebottom}><span className="nobr futuraboldfont">BOYSEN<span className={styles.registeredicon+" registeredicon"}>&reg;</span></span> Mobile App</div>
					</h2>

					<div className={styles.mobileappcontent}>
						<p>Get started with your home painting project with the <span className="nobr">BOYSEN<span className={styles.registeredicon+" registeredicon"}>&reg;</span></span> App!</p>

						<p>All the features you need to work on your project are now just a tap away on your mobile phone. </p>

						<p className="font-style-bold">Get inspired anytime, anywhere.</p>

						<div>
							<a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.boysen.android&hl=en&pli=1">
								<StaticImage layout={"fullWidth"}  src={imgboysenappgoogleplay} alt={"Get it on Google Play"} />
							</a>
							<a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/boysen/id791088396">
								<StaticImage layout={"fullWidth"}  src={imgboysenappappstore} alt={"Download on the App Store"} />
							</a>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);


}


export default HomePage;
